import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  convertToPence,
  convertDecimalToPercent,
  convertPercentToDecimal,
  convertToPounds,
  pluralize,
} from '@homamo/meadow-utils';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import CurrencyInput from '../../common/CurrencyInput';
import PercentageInput from '../../common/PercentageInput';
import TextInput from '../../common/TextInput';
import Textarea from '../../common/Textarea';
import LenderSelect from '../../lender/LenderSelect';

import API, { handleApiError } from '../../../lib/api';

const AdminEditMultipleRatesForm = ({
  successAction,
  cancelAction,
  selectedRates,
  selectedRate,
  mode,
}) => {
  const [displayError, setDisplayError] = React.useState();

  // eslint-disable-next-line no-unused-vars
  const selectedRateIds = selectedRates
    ? selectedRates.map((rate) => rate._id)
    : [];

  const validationSchema = Yup.object().shape({
    lender: Yup.string(),
    product: Yup.string(),
    rate: Yup.string(),
    ltvMin: Yup.string(),
    ltvMax: Yup.string().max(200, 'Enter value below 200%'),
    fee: Yup.string(),
    loanAmountMin: Yup.string(),
    loanAmountMax: Yup.string(),
  });

  let initialValues = {
    lender: undefined,
    product: undefined,
    rate: undefined,
    fee: undefined,
    ltvMin: undefined,
    ltvMax: undefined,
    loanAmountMin: undefined,
    loanAmountMax: undefined,
    info: undefined,
  };

  if (mode === 'single') {
    initialValues = {
      lender: selectedRate.lender_id || undefined,
      rateId: selectedRate.rateId || undefined,
      product: selectedRate.product || undefined,
      rate: selectedRate.rate
        ? convertDecimalToPercent(selectedRate.rate)
        : undefined,
      ltvMin: selectedRate.ltvMin
        ? convertDecimalToPercent(selectedRate.ltvMin)
        : undefined,
      ltvMax: selectedRate.ltvMax
        ? convertDecimalToPercent(selectedRate.ltvMax)
        : undefined,
      fee: selectedRate.fee ? convertToPounds(selectedRate.fee) : undefined,
      loanAmountMin: selectedRate.loanAmountMin
        ? convertToPounds(selectedRate.loanAmountMin)
        : undefined,
      loanAmountMax: selectedRate.loanAmountMax
        ? convertToPounds(selectedRate.loanAmountMax)
        : undefined,
      info: selectedRate.info || undefined,
    };
    validationSchema.rateId = Yup.string().required(
      'You need to enter a rate ID',
    );
  }

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const update = {
            lenderId: values.lender,
            product: values.product,
            rate: values.rate
              ? convertPercentToDecimal(values.rate)
              : undefined,
            fee: values.fee ? convertToPence(values.fee) : undefined,
            ltvMin: values.ltvMin
              ? convertPercentToDecimal(values.ltvMin)
              : undefined,
            ltvMax: values.ltvMax
              ? convertPercentToDecimal(values.ltvMax)
              : undefined,
            loanAmountMin: values.loanAmountMin
              ? convertToPence(values.loanAmountMin)
              : undefined,
            loanAmountMax: values.loanAmountMax
              ? convertToPence(values.loanAmountMax)
              : undefined,
            info: values.info,
          };

          if (mode === 'multi') update.selectedRateIds = selectedRateIds;

          await API.post(
            mode === 'multi'
              ? `rates/multi-update`
              : `rates/update/${selectedRate._id}`,
            update,
          )
            .then(() => {
              toast.success('Rates updated');
              if (successAction) successAction();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={({ errors, isSubmitting }) => {
          return (
            <Form>
              <Error error={errors} />
              <LenderSelect />
              {mode === 'single' && (
                <Field
                  label="Rate ID"
                  id="rateId"
                  name="rateId"
                  placeholder="Enter a Rate ID"
                  component={TextInput}
                  isRequired
                />
              )}
              <Field
                label="Product"
                id="product"
                name="product"
                placeholder="Enter a product name"
                component={TextInput}
              />
              <Field
                label="Min LTV"
                id="ltvMin"
                name="ltvMin"
                placeholder={0}
                component={PercentageInput}
              />
              <Field
                label="Max LTV"
                id="ltvMax"
                name="ltvMax"
                placeholder={0}
                max={200}
                component={PercentageInput}
              />
              <Field
                label="Rate"
                id="rate"
                name="rate"
                max={100}
                component={PercentageInput}
              />
              <Field
                label="Fee"
                id="fee"
                name="fee"
                component={CurrencyInput}
              />
              <Field
                label="Min loan amount"
                id="loanAmountMin"
                name="loanAmountMin"
                component={CurrencyInput}
              />
              <Field
                label="Max loan amount"
                id="loanAmountMax"
                name="loanAmountMax"
                component={CurrencyInput}
              />
              <Field label="Info" id="info" name="info" component={Textarea} />
              <ButtonGroup>
                <Button
                  label={
                    mode === 'single'
                      ? 'Update rate'
                      : `Update ${selectedRateIds.length} ${pluralize(
                          selectedRateIds.length,
                          'rate',
                        )}`
                  }
                  type="submit"
                  mode="primary"
                  loadingLabel="Updating"
                  isLoading={isSubmitting}
                />
                {cancelAction && (
                  <Button label="Cancel" onClick={cancelAction} />
                )}
              </ButtonGroup>
            </Form>
          );
        }}
      />
    </>
  );
};

const selectedRateProps = PropTypes.shape({
  _id: PropTypes.string,
  lender_id: PropTypes.string,
  product: PropTypes.string,
  rateId: PropTypes.string,
  rate: PropTypes.number,
  fee: PropTypes.number,
  loanAmountMin: PropTypes.number,
  loanAmountMax: PropTypes.number,
  ltvMin: PropTypes.number,
  ltvMax: PropTypes.number,
  info: PropTypes.string,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,
});

AdminEditMultipleRatesForm.propTypes = {
  successAction: PropTypes.func,
  cancelAction: PropTypes.func,
  selectedRates: PropTypes.arrayOf(selectedRateProps),
  selectedRate: selectedRateProps,
  mode: PropTypes.string,
};

AdminEditMultipleRatesForm.defaultProps = {
  successAction: undefined,
  cancelAction: undefined,
  selectedRates: [],
  selectedRate: {},
  mode: 'multi',
};

export default AdminEditMultipleRatesForm;
