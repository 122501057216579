/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  usePagination,
  useSortBy,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import Pagination from './lib/Pagination';
import Filter from './lib/Filter';
import Search from './lib/Search';
import Styles from './Styles';

const updateUrlTableQueries = ({
  tableName,
  pageIndex,
  pageSize,
  searchTerm,
  sortBy,
  selectedQuickFilter,
  selectedAdvancedFilters,
}) => {
  const params = new URLSearchParams(window.location.search);

  function setParam(field, value) {
    if (value !== undefined && value !== null) {
      params.set(field, value);
    } else {
      params.delete(field);
    }
  }

  setParam(`${tableName}_pageIndex`, pageIndex);
  setParam(`${tableName}_pageSize`, pageSize);
  setParam(`${tableName}_searchTerm`, searchTerm);
  setParam(`${tableName}_selectedQuickFilter`, selectedQuickFilter);
  if (sortBy && sortBy[0]) {
    setParam(`${tableName}_sortBy`, sortBy[0].id);
    setParam(`${tableName}_sortByDesc`, sortBy[0].desc);
  }

  if (selectedAdvancedFilters && selectedAdvancedFilters.length > 0) {
    selectedAdvancedFilters.forEach(({ field, value }) =>
      params.set(`${tableName}_filter_${field}`, value),
    );
  }

  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
};

function getInitialAdvancedFilter({ tableName, query }) {
  const advancedFilter = [];

  query.forEach((value, key) => {
    if (key.includes(`${tableName}_filter_`)) {
      const field = key.split(`${tableName}_filter_`)[1];
      advancedFilter.push({ field, value });
    }
  });

  return advancedFilter;
}

function TableComponent({
  name,
  columns,
  data,
  fetchData,
  isLoading,
  pageCount: controlledPageCount,
  // blankState,
  totalCount,
  enableSearch,
  searchPlaceholder,
  initialSortBy,
  defaultPageSize,
  quickFilterOptions,
  advancedFilters,
  isCompact,
  hiddenColumns,
}) {
  // GET READY FOR PARAMS
  const location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();

  const [searchTerm, setSearchTerm] = React.useState(
    query.get(`${name}_searchTerm`) || '',
  );
  const [selectedQuickFilter, setSelectedQuickFilter] = React.useState(
    query.get(`${name}_selectedQuickFilter`) || undefined,
  );
  const [selectedAdvancedFilters, setSelectedAdvancedFilters] = React.useState(
    getInitialAdvancedFilter({ query, tableName: name }) || [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    [],
  );

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups if your table have groupings
    prepareRow, // Prepare the row (this function need to called for each row before getting the row props)
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: parseInt(query.get(`${name}_pageIndex`), 10) || 0,
        sortBy: query.get(`${name}_sortBy`)
          ? [
              {
                id: query.get(`${name}_sortBy`),
                desc: query.get(`${name}_sortByDesc`) || false,
              },
            ]
          : initialSortBy,
        pageSize:
          parseInt(query.get(`${name}_pageSize`), 10) || defaultPageSize,
        hiddenColumns,
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      manualSortBy: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns,
    useSticky,
  );

  // Update Fetch Data
  React.useEffect(() => {
    if (fetchData) {
      fetchData({
        pageIndex,
        pageSize,
        searchTerm,
        sortBy,
        selectedQuickFilter,
        selectedAdvancedFilters,
      });
    }
    updateUrlTableQueries({
      tableName: name,
      pageIndex,
      pageSize,
      searchTerm,
      sortBy,
      selectedQuickFilter,
      selectedAdvancedFilters,
    });
  }, [
    name,
    fetchData,
    pageIndex,
    pageSize,
    searchTerm,
    sortBy,
    selectedQuickFilter,
    selectedAdvancedFilters,
  ]);

  const hasQuickFilter = quickFilterOptions && quickFilterOptions.length > 0;
  const hasAdvancedFilters = advancedFilters && advancedFilters.length > 0;

  return (
    <Styles.Table className={`${isLoading && '--loading'}`}>
      {(enableSearch || hasQuickFilter || hasAdvancedFilters) && (
        <>
          {enableSearch && (
            <Search
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              placeholder={searchPlaceholder}
              isLoading={isLoading}
            />
          )}
          {quickFilterOptions.length > 0 && (
            <Filter
              quickFilterOptions={quickFilterOptions}
              advancedFilters={advancedFilters}
              selectedQuickFilter={selectedQuickFilter}
              setSelectedQuickFilter={setSelectedQuickFilter}
              selectedAdvancedFilters={selectedAdvancedFilters}
              setSelectedAdvancedFilters={setSelectedAdvancedFilters}
            />
          )}
        </>
      )}
      <div className="table-container">
        <table {...getTableProps()} className="table sticky">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="th">
                    <div
                      {...column.getSortByToggleProps()}
                      className="header-content"
                    >
                      {column.render('Header')}

                      <span
                        className={`sorted-indicator ${
                          column.isSorted && '--toggled'
                        }`}
                      >
                        {column.canSort && (
                          <FontAwesomeIcon
                            icon={
                              column.isSortedDesc
                                ? 'sort-amount-down'
                                : 'sort-amount-up'
                            }
                          />
                        )}
                      </span>
                    </div>
                    {/* Use column.getResizerProps to hook up the events correctly */}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="body">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="tr">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="td">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="footer">
        {isLoading ? (
          <span>
            <FontAwesomeIcon icon="spinner" spin /> Loading...
          </span>
        ) : (
          <span>
            Showing {page.length} of {totalCount} results
          </span>
        )}
      </div>

      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        isCompact={isCompact}
      />
    </Styles.Table>
  );
}

TableComponent.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  fetchData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  blankState: PropTypes.string,
  enableSearch: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  quickFilterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  advancedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      type: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ),
  isCompact: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  heading: PropTypes.string,
};

TableComponent.defaultProps = {
  columns: [],
  data: [],
  isLoading: false,
  pageCount: 0,
  totalCount: 0,
  blankState: undefined,
  enableSearch: false,
  searchPlaceholder: undefined,
  initialSortBy: undefined,
  quickFilterOptions: [],
  advancedFilters: [],
  isCompact: false,
  defaultPageSize: 20,
  hiddenColumns: [],
  heading: 'Filter records',
};

export default TableComponent;
