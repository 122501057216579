import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from '@homamo/meadow-utils';

import Styles from './Styles';

import HeaderInfoBlock from '../../common/HeaderInfoBlock';
import HeaderInfoGroup from '../../common/HeaderInfoGroup';
import PageIntro from '../../common/PageIntro';

const HistoryHeader = ({ heading, description, blocks, updatedAt }) => {
  return (
    <Styles.HistoryHeader>
      <div className="top">
        <PageIntro heading={heading} text={description}>
          <HeaderInfoGroup center>
            {blocks.map(({ label, value }) => (
              <HeaderInfoBlock key={label} label={label} value={value} />
            ))}
          </HeaderInfoGroup>
          {updatedAt && (
            <p className="sub">
              Last updated: {formatDateTime(updatedAt, 'date')}
            </p>
          )}
        </PageIntro>
      </div>
    </Styles.HistoryHeader>
  );
};

HistoryHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  updatedAt: PropTypes.string.isRequired,
};

HistoryHeader.defaultProps = {
  blocks: [],
};

export default HistoryHeader;
