/* eslint-disable react/prop-types */
import React from 'react';

import Button, { ButtonGroup } from '../../../Button';
import Styles from './Styles';

const PaginationComponent = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  isCompact,
}) => {
  return (
    <Styles.Pagination>
      <ButtonGroup>
        <Button
          icon="angle-double-left"
          isIconButton
          size={isCompact ? 's' : 'm'}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        />
        <Button
          icon="angle-left"
          isIconButton
          size={isCompact ? 's' : 'm'}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />
      </ButtonGroup>
      <Styles.PageDetails className="page-details">
        <span>
          Page{' '}
          <strong>
            {pageOptions.length < pageIndex + 1
              ? pageOptions.length
              : pageIndex + 1}{' '}
            of {pageOptions.length}
          </strong>{' '}
        </span>
        {/* <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '} */}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </Styles.PageDetails>

      <ButtonGroup>
        <Button
          icon="angle-right"
          isIconButton
          size={isCompact ? 's' : 'm'}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        />
        <Button
          icon="angle-double-right"
          isIconButton
          size={isCompact ? 's' : 'm'}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        />
      </ButtonGroup>
    </Styles.Pagination>
  );
};

export default PaginationComponent;
