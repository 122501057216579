import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatTerm } from '@rateswitch/amm-utils';

import { formatCurrency } from '@homamo/meadow-utils';

import Panel from '../../common/Panel';
import Styles from './Styles';

class BalanceTrackerComponent extends Component {
  renderProgress() {
    const {
      initialBalance,
      remainingBalance,
      estimatedPropertyValue,
    } = this.props;

    const progress =
      initialBalance > estimatedPropertyValue
        ? remainingBalance / initialBalance
        : remainingBalance / estimatedPropertyValue;

    return (
      <Styles.ProgressBar>
        {/* <span>{formatCurrency(remainingBalance)}</span> */}
        <div style={{ width: `${(1 - progress) * 100}%` }} />
      </Styles.ProgressBar>
    );
  }

  render() {
    const {
      initialBalance,
      estimatedPropertyValue,
      monthlyOverpayment,
      termReduction,
      interestSaved,
      remainingBalance,
    } = this.props;

    const equityMode = remainingBalance < estimatedPropertyValue;

    return (
      <Styles.BalanceTracker>
        <Panel
          heading={equityMode ? 'Your equity' : 'Your progress'}
          smallHeader
        >
          {this.renderProgress()}
          <small>
            {equityMode
              ? `Property: ${formatCurrency(
                  estimatedPropertyValue,
                )} | Equity: ${formatCurrency(
                  estimatedPropertyValue - remainingBalance,
                )}`
              : `Initial balance: ${formatCurrency(initialBalance)}`}
          </small>
          <div>
            {monthlyOverpayment ? (
              <p>
                If you keep up your monthly overpayments you&apos;ll be mortgage
                free <strong>{formatTerm(termReduction)}</strong> sooner which
                could save you a further{' '}
                <strong>
                  {formatCurrency(interestSaved, { wholePounds: true })}
                </strong>{' '}
                interest on your remaining balance
              </p>
            ) : null}
          </div>
        </Panel>
      </Styles.BalanceTracker>
    );
  }
}

BalanceTrackerComponent.defaultProps = {
  monthlyOverpayment: 0,
  termReduction: 0,
  interestSaved: 0,
};

BalanceTrackerComponent.propTypes = {
  initialBalance: PropTypes.number.isRequired,
  remainingBalance: PropTypes.number.isRequired,
  estimatedPropertyValue: PropTypes.number.isRequired,
  monthlyOverpayment: PropTypes.number,
  termReduction: PropTypes.number,
  interestSaved: PropTypes.number,
};

export default BalanceTrackerComponent;
