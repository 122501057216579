import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import API, { handleApiError } from '../../../lib/api';
import AdminRatesTable from './Component';
import useDocListState from './state';
import LenderSelect from '../../lender/LenderSelect';
import AdminEditRatesModal from '../AdminEditRatesModal';
import Button, { Styles as ButtonStyles } from '../../common/Button';

import Error from '../../common/ErrorMessage';

function AdminRatesTableContainer({
  lenderId,
  fixedLender,
  displayError,
  setDisplayError,
}) {
  // GET READY FOR PARAMS
  const location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();

  /*
   * Delete rate is used to delete from the state cache
   * rather than reload data from the API
   */

  // We'll start our table without any data
  const { rates, setRates, deleteRate } = useDocListState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [selectedLenderId, setSelectedLenderId] = React.useState(
    lenderId || query.get(`rates_selectedLenderId`) || 'ALL',
  );
  const [selectedRates, setSelectedRates] = React.useState([]);

  const fetchIdRef = React.useRef(0);
  const initialSortBy = [{ id: 'createdAt', desc: false }];

  // Clear selected rates on refresh
  React.useEffect(() => {
    setSelectedRates([]);
  }, []);

  const fetchData = React.useCallback(
    async ({
      pageSize = 20,
      pageIndex = 0,
      searchTerm,
      sortBy = initialSortBy,
      selectedFilter,
    } = {}) => {
      // Give this fetch an ID
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;

      // LENDER PARAM SETTING
      const urlParams = new URLSearchParams(window.location.search);
      function setParam(field, value) {
        if (value !== undefined && value !== null) {
          urlParams.set(field, value);
        } else {
          urlParams.delete(field);
        }
      }
      setParam(`rates_selectedLenderId`, selectedLenderId);
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${urlParams}`,
      );

      // -----

      if (fetchId === fetchIdRef.current) {
        // Set the loading state
        setLoading(true);

        const params = {
          searchTerm,
          pageSize,
          pageIndex,
          status: selectedFilter,
          lenderId: selectedLenderId,
        };

        if (sortBy && sortBy.length > 0) {
          params.sortBy = sortBy[0].id;
          params.sortByDesc = sortBy[0].desc;
        }

        const response = await API.get('rates/', { params }).catch((error) => {
          handleApiError({ error, setDisplayError });
        });

        if (response) {
          setRates(response.data.data);
          setPageCount(Math.ceil(response.data.totalCount / pageSize));
          setTotalCount(response.data.totalCount);
        }

        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLenderId],
  );

  const addSelectedRate = (selectedRate) => {
    const existingRates = selectedRates.filter(
      (r) => r._id === selectedRate._id,
    );

    if (existingRates.length > 0) {
      setDisplayError({
        message: `${selectedRate.rateId} already selected`,
      });
    } else {
      setSelectedRates((current) => [...current, selectedRate]);
    }
  };

  const removeSelectedRate = (removedRate) => {
    setSelectedRates((current) => [
      ...current.filter((r) => r !== removedRate),
    ]);
  };

  return (
    <>
      <Error error={displayError} />
      <ButtonStyles.ButtonGroup>
        <Button
          label="Create rate"
          mode="primary"
          linkTo="/admin/create-rate"
          size="s"
        />
        <AdminEditRatesModal
          buttonSize="s"
          selectedRates={selectedRates}
          successAction={() => {
            setSelectedRates([]);
            fetchData();
          }}
        />
      </ButtonStyles.ButtonGroup>
      {!fixedLender && (
        <LenderSelect
          onChange={(e) => setSelectedLenderId(e.target.value)}
          value={selectedLenderId}
          setValue={setSelectedLenderId}
          includeBlank
          noFormik
        />
      )}
      <AdminRatesTable
        data={rates}
        isLoading={loading}
        pageCount={pageCount}
        fetchData={fetchData}
        totalCount={totalCount}
        initialSortBy={initialSortBy}
        onRateDeleted={(rateId) => deleteRate(rateId)}
        fixedLender={fixedLender}
        selectedRates={selectedRates}
        onSelect={addSelectedRate}
        onDeselect={removeSelectedRate}
      />
    </>
  );
}

AdminRatesTableContainer.propTypes = {
  lenderId: PropTypes.string,
  fixedLender: PropTypes.bool,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
  setDisplayError: PropTypes.func.isRequired,
};

AdminRatesTableContainer.defaultProps = {
  lenderId: undefined,
  fixedLender: false,
  displayError: {},
};

export default AdminRatesTableContainer;
