import React from 'react';
import PropTypes from 'prop-types';

import { getRemainingTerm, getSwitchStatus } from '@rateswitch/amm-utils';

import { useAuth } from '../../../context/auth';
import Error from '../../common/ErrorMessage';
import PageIntro from '../../common/PageIntro';
import PageContent from '../../layout/PageContent';
import NoRates from '../NoRates';
import RateswitchBenefits from '../RateswitchBenefits';
import RewardsMessage from '../RewardsMessage';
import ArrangeCallback from '../ArrangeCallback';
import Rates from '../Rates';

const SwitchView = ({ mortgage, displayError }) => {
  const { user } = useAuth();

  const {
    remainingBalance,
    interestRate,
    monthlyPayment,
    fixedRate,
    fixedRateEndDate,
    switchInProgress,
    estimatedPropertyValue,
  } = mortgage;

  const lender = mortgage.lender || {};

  if (switchInProgress) {
    return (
      <PageContent>
        <Error error={displayError} />
        <NoRates
          heading="You currently have a switch in progress"
          text="Please contact your Rateswitch adviser directly if you have any questions about your switch"
        />
      </PageContent>
    );
  }

  const status = getSwitchStatus({
    fixedRate,
    fixedRateEndDate,
    interestRate,
  });

  const remainingTerm = getRemainingTerm({
    remainingBalance,
    interestRate,
    monthlyPayment,
  });

  const title = {
    now: 'Find a better rate',
    soon: 'Explore your switching options',
    future: 'You’ve got time',
    unknown: 'Explore your switching options',
  }[status.label];

  const introMessage = {
    now:
      'Start your switch and our expert mortgage advisers will help you access the best rates from your existing lender and across the mortgage market',
    soon:
      'It’ll be time to switch soon, so we’re tracking your current lender’s best rates daily',
    future:
      'You’re currently on a fixed rate, but you can still accelerate towards mortgage freedom',
    unknown:
      lender && lender.showRates
        ? 'You’ve told us you’re not currently fixed, so we may be able to switch you to a better rate. Take a look at your current lender’s rates below.'
        : 'You’ve told us you’re not currently fixed, so we may be able to switch you to a better rate.',
  }[status.label];

  if (status.label !== 'future' && (!lender || !lender.showRates)) {
    return (
      <PageContent>
        <Error error={displayError} />
        <NoRates
          heading={`We'll help you find a better rate with ${
            lender.name || 'your lender'
          }`}
          text="Arrange a callback and one of our expert mortgage advisers
            will be in touch to discuss your switching options and the
            best rates available."
        >
          <ArrangeCallback
            buttonLabel="Arrange a callback"
            buttonMode="primary"
            buttonBlock
            userPhone={user.profile.phone}
            context="moreInfo"
          />
        </NoRates>
        <RateswitchBenefits userPhone={user.profile.phone} userId={user._id} />
      </PageContent>
    );
  }

  return (
    <PageContent>
      <Error error={displayError} />
      <PageIntro heading={title} text={introMessage} compact />
      {status.label === 'future' ? (
        <RewardsMessage
          remainingBalance={remainingBalance}
          interestRate={interestRate}
          monthlyPayment={monthlyPayment}
        />
      ) : (
        <RateswitchBenefits userPhone={user.profile.phone} />
      )}
      {lender.showRates && (
        <>
          <PageIntro
            heading={`${lender.name || 'Your lender'} is currently
            offering these rates to existing customers`}
            text="These rates are based on your details"
            compact
          />
          <Rates
            lenderId={lender._id}
            eligibleForSwitch={status.label === 'now'}
            loanAmount={remainingBalance}
            loanToValue={remainingBalance / estimatedPropertyValue}
            userPhone={user.profile.phone}
            userId={user._id}
            remainingTerm={remainingTerm}
          />
          {lender.representativeExample && (
            <small>{lender.representativeExample}</small>
          )}
        </>
      )}
    </PageContent>
  );
};

SwitchView.propTypes = {
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
  mortgage: PropTypes.shape({
    initialBalance: PropTypes.number,
    monthlyOverpayment: PropTypes.number,
    remainingBalance: PropTypes.number,
    estimatedPropertyValue: PropTypes.number,
    interestRate: PropTypes.number,
    monthlyPayment: PropTypes.number,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
    switchInProgress: PropTypes.bool,
    lender: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      representativeExample: PropTypes.string,
      showRates: PropTypes.bool,
    }),
  }).isRequired,
};

SwitchView.defaultProps = {
  displayError: undefined,
};

export default SwitchView;
