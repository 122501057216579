import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  checkUserHasBetaAccess,
  checkUserHasOneOfRoles,
} from '@homamo/meadow-utils';

import Styles from './Styles';
import { useAuth } from '../../../context/auth';

import config from '../../../config';
import SignOut from '../../auth/SignOut';
import MarketingSiteLink from '../MarketingSiteLink';

const AccountMenu = () => {
  const { user } = useAuth();

  return (
    <Styles.AccountMenu>
      <span>
        My Account
        <FontAwesomeIcon icon="caret-down" />
      </span>
      <Styles.AccountMenuDropDown>
        <Link to="/account/referrals">Refer a friend</Link>
        <MarketingSiteLink path="/help" label="How it works" />
        <Link to="/account">Account Settings</Link>
        <Link to="/mortgage/settings">Mortgage Settings</Link>
        <Link to="/history">Transaction History</Link>
        {checkUserHasBetaAccess(user) && (
          <Link to="/rewards/history">Reward History</Link>
        )}
        {checkUserHasOneOfRoles(user, ['ADMIN']) && (
          <Link to="/admin">Admin</Link>
        )}
        {config.supportEmail && (
          <a href={`mailto:${config.supportEmail}`}>Support</a>
        )}
        <SignOut />
      </Styles.AccountMenuDropDown>
    </Styles.AccountMenu>
  );
};

export default AccountMenu;
