import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  formatCurrency,
  formatDateTime,
  formatPercentage,
  keepParams,
} from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Table, { TableButtonGroup } from '../../common/Table';
import DeleteRate from '../DeleteRate';
import AdminEditRatesModal from '../AdminEditRatesModal';

const AdminRatesTableComponent = ({
  data,
  isLoading,
  pageCount,
  fetchData,
  totalCount,
  initialSortBy,
  filterOptions,
  onRateDeleted,
  isCompact,
  fixedLender,
  selectedRates,
  mode,
  onSelect,
  onDeselect,
  hideActions,
}) => {
  const selectedRateIds = selectedRates
    ? selectedRates.map((rate) => rate._id)
    : [];

  const columns = useMemo(
    /* eslint-disable react/prop-types */
    () => [
      {
        Header: () => <FontAwesomeIcon icon="hand-pointer" />,
        accessor: '_id',
        id: 'multiSelect',
        width: 50,
        disableSortBy: true,
        sticky: 'left',
        Cell: ({ row: { original } }) => (
          <input
            type="checkbox"
            onChange={(e) => {
              const { checked } = e.target;
              if (checked && onSelect) {
                onSelect(original);
              } else if (onDeselect) {
                onDeselect(original);
              }
            }}
            defaultChecked={selectedRateIds.includes(original._id)}
            disabled={
              original.completionStatus === 'removed' ||
              original.completionStatus === 'report_submitted'
            }
          />
        ),
      },
      {
        Header: 'Lender',
        accessor: 'lender.name',
        sortType: 'basic',
        id: 'lender.name',
        minWidth: 180,
      },
      {
        Header: 'Product',
        accessor: 'product',
        sortType: 'basic',
        id: 'product',
      },
      {
        Header: 'Rate ID',
        accessor: 'rateId',
        sortType: 'basic',
        id: 'rateId',
      },
      {
        Header: 'Min LTV',
        accessor: ({ ltvMin }) => formatPercentage(ltvMin, 2),
        sortType: 'basic',
        id: 'ltvMin',
      },
      {
        Header: 'Max LTV',
        accessor: ({ ltvMax }) => formatPercentage(ltvMax, 2),
        sortType: 'basic',
        id: 'ltvMax',
      },
      {
        Header: 'Rate',
        accessor: ({ rate }) => formatPercentage(rate, 2),
        sortType: 'basic',
        id: 'rate',
      },
      {
        Header: 'Fee',
        accessor: ({ fee }) => formatCurrency(fee),
        sortType: 'basic',
        id: 'fee',
      },
      {
        Header: 'Min Loan',
        accessor: ({ loanAmountMin }) => formatCurrency(loanAmountMin),
        sortType: 'basic',
        id: 'loanAmountMin',
      },
      {
        Header: 'Max Loan',
        accessor: ({ loanAmountMax }) => formatCurrency(loanAmountMax),
        sortType: 'basic',
        id: 'loanAmountMax',
      },
      {
        Header: 'Created',
        accessor: ({ createdAt }) => formatDateTime(createdAt),
        sortType: 'datetime',
        id: 'createdAt',
      },
      {
        Header: 'Updated',
        accessor: ({ updatedAt }) => formatDateTime(updatedAt),
        sortType: 'datetime',
        id: 'updatedAt',
      },
      {
        Header: 'Actions',
        accessor: '_id',
        disableSortBy: true,
        width: 200,
        sticky: 'right',
        Cell: ({ cell: { value }, row: { original } }) => (
          <TableButtonGroup>
            <Button
              label="View"
              size="s"
              linkTo={keepParams(`/admin/rate/${value}`)}
            />
            <AdminEditRatesModal
              buttonSize="s"
              buttonLabel="Edit"
              mode="single"
              selectedRate={original}
              successAction={() => {
                fetchData();
              }}
            />
            <DeleteRate id={value} onRateDeleted={onRateDeleted} />
          </TableButtonGroup>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data], // Second array to allow memo
    /* eslint-enable react/prop-types */
  );

  const hiddenColumns = [];
  if (mode !== 'select') hiddenColumns.push('select');
  if (mode !== 'multiSelect') hiddenColumns.push('multiSelect');
  if (hideActions) hiddenColumns.push('actions');

  return (
    <>
      <Table
        name="rates"
        columns={columns}
        data={data}
        fetchData={fetchData}
        isLoading={isLoading}
        pageCount={pageCount}
        totalCount={totalCount}
        blankState="No rates found"
        initialSortBy={initialSortBy}
        searchPlaceholder="Search for a product"
        filterOptions={filterOptions}
        isCompact={isCompact}
        enableSearch={!fixedLender}
        fixedLender={fixedLender}
      />
    </>
  );
};

AdminRatesTableComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  onRateDeleted: PropTypes.func,
  isLoading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ).isRequired,
  isCompact: PropTypes.bool,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  fixedLender: PropTypes.bool,
  selectedRates: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
  mode: PropTypes.string,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  hideActions: PropTypes.bool,
};

AdminRatesTableComponent.defaultProps = {
  data: [],
  onRateDeleted: undefined,
  isLoading: false,
  pageCount: 0,
  totalCount: 0,
  isCompact: false,
  filterOptions: [],
  fixedLender: false,
  selectedRates: [],
  mode: 'default',
  onSelect: undefined,
  onDeselect: undefined,
  hideActions: false,
};

export default AdminRatesTableComponent;
