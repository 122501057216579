import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency, formatDateTime } from '@homamo/meadow-utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import Button from '../../common/Button';
import LockedCell from '../../common/LockedCell';
import Table from '../../common/Table';
import RewardStatusBadge from '../RewardStatusBadge';

const renderDetails = ({ retailer }) => {
  if (retailer && retailer.heading) return retailer.heading;

  return <em>Awaiting retailer...</em>;
};

const RewardsTableComponent = ({
  data,
  isLoading,
  pageCount,
  fetchData,
  totalCount,
  initialSortBy,
  filterOptions,
  isCompact,
}) => {
  const columns = useMemo(
    /* eslint-disable react/prop-types */
    () => [
      {
        Header: () => <FontAwesomeIcon icon="lock" />,
        accessor: 'locked',
        id: 'locked',
        resizable: false,
        width: 50,
        disableSortBy: true,
        Cell: ({ value }) => <LockedCell isLocked={value} />,
      },
      {
        Header: 'Date',
        accessor: ({ date }) => formatDateTime(date),
        sortType: 'datetime',
        id: 'date',
        width: 110,
      },
      {
        Header: 'Type',
        accessor: ({ type }) => <span>{type}</span>,
        sortType: 'basic',
        id: 'type',
        width: 110,
      },
      {
        Header: 'Amount',
        accessor: ({ amount }) => formatCurrency(amount),
        sortType: 'basic',
        id: 'amount',
      },
      {
        Header: 'Details',
        width: 240,
        accessor: ({ retailer }) => <span>{renderDetails({ retailer })}</span>,
        id: 'details',
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: (reward) => <RewardStatusBadge reward={reward} size="s" />,
        sortType: 'basic',
        id: 'status',
      },
    ],
    [], // Second array to allow memo
    /* eslint-enable react/prop-types */
  );

  return (
    <>
      <Table
        name="rewards"
        columns={columns}
        data={data}
        fetchData={fetchData}
        isLoading={isLoading}
        pageCount={pageCount}
        totalCount={totalCount}
        blankState="No rewards found"
        initialSortBy={initialSortBy}
        filterOptions={filterOptions}
        isCompact={isCompact}
      />
    </>
  );
};

RewardsTableComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ).isRequired,
  isCompact: PropTypes.bool,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

RewardsTableComponent.defaultProps = {
  data: [],
  isLoading: false,
  pageCount: 0,
  totalCount: 0,
  isCompact: false,
  filterOptions: [],
};

export default RewardsTableComponent;
