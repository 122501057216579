import React from 'react';
import { Redirect, useLocation, Link } from 'react-router-dom';

import { useAuth } from '../../../context/auth';
import config from '../../../config';

import SignInForm from './Form';
import LayoutWithImage from '../../layout/LayoutWithImage/Component';
import Message from '../../common/Message';

import relaxImg from '../../../images/relax.svg';

const SignInView = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const referer = (location.state && location.state.referer) || '/';

  if (isAuthenticated) {
    return <Redirect to={referer} />;
  }

  return (
    <LayoutWithImage image={relaxImg} padTop>
      <h1>Sign in to view your account</h1>
      <p>
        We help homeowners take years off their mortgage and save thousands of
        pounds in interest
      </p>
      {!config.signUpDisabled && (
        <p>
          Don&apos;t have an account yet?{' '}
          <Link to="/sign-up">Sign up here</Link>
        </p>
      )}
      {config.isStaging && (
        <Message mode="warning">
          This is the testing version of AMM. If you&apos;re here by accident,
          you can{' '}
          <a href="https://app.acceleratemymortgage.com">
            sign in to the public version here.
          </a>
        </Message>
      )}
      <SignInForm />
    </LayoutWithImage>
  );
};

export default SignInView;
