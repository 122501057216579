import styled from 'styled-components';

const Footer = styled.footer`
  display: block;
  margin: ${({ theme }) => theme.space[4]} auto 0;
  padding: ${({ theme }) => `${theme.space[6]} ${theme.space[3]}`};

  color: ${({ theme }) => theme.color['gray-700']};
  background-color: ${({ theme }) => theme.color.white};

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: ${({ theme }) => theme.space[3]} 0;
    }

    @media ${({ theme }) => theme.device.tablet} {
      display: flex;
      flex-direction: row;
    }

    a {
      margin-right: ${({ theme }) => theme.space[3]};
      color: ${({ theme }) => theme.color['blue-600']};
    }
  }

  p {
    color: ${({ theme }) => theme.color['gray-700']};
    max-width: 100%;
  }
`;

const FooterInner = styled.div`
  max-width: ${({ theme }) => theme.space[18]};
  margin: 0 auto;
`;

export default {
  Footer,
  FooterInner,
};
